
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import jQuery from 'jquery';

require("./assets/css/flatsome.css")
require("./assets/css/flatsome-shop.css")
require("./assets/css/splide.min.css")
require("./assets/css/style.css")
 

const app = createApp(App)
app.config.globalProperties.$ = jQuery;
app.use(router)
app.mount('#app')
