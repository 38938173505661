import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'; 
axios.defaults.withCredentials = true;
/* Layout */

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: () => import('@/layouts'),
        },
        {
            path: '/category/:slug',
            name: 'category',
            component: () => import('@/layouts/category.vue')
        },
        {
            path: '/product/:slug',
            name: 'product',
            component: () => import('@/layouts/product.vue')
        },
        {
            path: '/posts/:slug',
            name: 'posts',
            component: () => import('@/layouts/posts.vue')
        },
        {
            path: '/post/:slug',
            name: 'post',
            component: () => import('@/layouts/post.vue')
        },
        {
            path: '/cart/',
            name: 'cart',
            component: () => import('@/layouts/CartView.vue')
        }, 
        {
            path: '/checkout/',
            name: 'checkout',
            component: () => import('@/layouts/CheckoutView.vue')
        }, 
        {
            path: '/order-success/',
            name: 'LayoutSuccess',
            component: () => import('@/layouts/ordersuccess.vue')
        }, 
        {
            path: '/:catchAll(.*)',
            name: 'NotFound',
            component: () => import('@/layouts/NotFound.vue'),
        },
        {
            path: '/search',
            name: 'Search',
            component:  () => import('@/layouts/search.vue'),
            props: route => ({ query: route.query.s }), 
        },
        {
            path: '/brands/',
            name: 'LayoutThuonghieu',
            component: () => import('@/layouts/brands.vue')
        }, 
        {
            path: '/brands/:slug', 
            name: 'LayoutCatethuongHieu',
            component: () => import('@/layouts/categorybrands.vue')
        },

    ]
})

export default router
